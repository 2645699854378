import { css } from '@emotion/react'
import { Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { pxToRemUnit } from 'utils/styled'
import { Card } from 'components/card/Card'
import { Logo } from 'components/content/mediaMentions/mentionCard/Logo'
import { Person } from 'components/content/mediaMentions/mentionCard/Person'
import { Stars } from 'components/content/mediaMentions/mentionCard/Stars'
import { Mention } from 'components/content/mediaMentions/mention'

export function MentionCard(props: { mention: Mention; className?: string }) {
  const hasLogo = !!props.mention.logo?.url

  const card = (
    <Card
      css={css`
        padding: ${pxToRemUnit(28)};
        overflow: hidden;
      `}
      className={props.className}
      hoverable={!!props.mention.url}
    >
      <Stack
        space={'1.25rem'}
        css={css`
          text-align: left;
        `}
      >
        <Stars count={props.mention.stars} />
        <Text variant="bodyLargeBold" color="black">
          {props.mention.brief}
        </Text>
        <Text variant="italic">{props.mention.citation}</Text>
        {hasLogo ? <Logo mention={props.mention} /> : <Person mention={props.mention} />}
      </Stack>
    </Card>
  )

  if (props.mention.url) {
    return (
      <a
        href={props.mention.url || ''}
        css={css`
          text-decoration: none;
        `}
        target="_blank"
        rel="noreferrer"
      >
        {card}
      </a>
    )
  }

  return card
}
