import { css } from '@emotion/react'
import { BREAKPOINTS } from 'utils/styled'
import AliceCarousel from 'react-alice-carousel'
import { DragEvent, TouchEvent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

import 'react-alice-carousel/lib/alice-carousel.css'
import { MentionCard } from './mentionCard/MentionCard'
import { Controls } from '../mediaMentions/Controls'
import { Spacer } from 'components/spacer/Spacer'
import { MentionItem } from 'api/contentful/contentful.graphql.types'

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const CarouselWrapper = styled.div`
  width: calc(90%);
  transform: translateX(5%);

  @media ${BREAKPOINTS.MD.max} {
    width: calc(80%);
    transform: unset;
  }
  .alice-carousel__wrapper {
    overflow: visible;
  }
`

export function MediaMentionsSwiper(props: { mentions: MentionItem[] }) {
  const carousel = useRef<AliceCarousel>(null)

  // This is a workaround to disable infinite on SSR, then enable it back on client side
  // Here is the issue this workaround fixes: https://cleevio.atlassian.net/browse/GT-4591
  // More info: https://github.com/maxmarinich/react-alice-carousel/issues/167
  const [isInfinite, setIsInfinite] = useState(false)
  useEffect(() => {
    setIsInfinite(true)
  }, [])

  function stopOnManualSlide(e: DragEvent | TouchEvent) {
    carousel.current?.setState((state) => ({ ...state, isAutoPlaying: false }))
    e.preventDefault()
  }

  return (
    <Container>
      <CarouselWrapper>
        <AliceCarousel
          ref={carousel}
          mouseTracking
          autoPlay
          animationEasingFunction="ease"
          autoPlayInterval={4000}
          animationDuration={400}
          items={props.mentions?.map((mention, i) => (
            <div
              key={i}
              css={css`
                // card shadow is cropped otherwise
                padding: 30px 1rem;
              `}
              draggable={true}
              onDragStart={stopOnManualSlide}
              onTouchMove={stopOnManualSlide}
            >
              <MentionCard
                mention={mention}
                css={css`
                  ${!mention?.url ? `cursor: grab;` : null}
                `}
              />
            </div>
          ))}
          infinite={isInfinite}
          responsive={{
            0: { items: 1 },
            [BREAKPOINTS.MD.size]: { items: 3 },
            [BREAKPOINTS.LG.size]: { items: 4 },
            1920: { items: 5 },
            2560: { items: 7 },
          }}
          disableDotsControls
          disableButtonsControls
        />
      </CarouselWrapper>
      <div className="desktop-only">
        <Spacer size={2} />
        <Controls
          slideNext={(e) => {
            carousel.current?.setState((state) => ({ ...state, isAutoPlaying: false }))
            carousel.current?.slideNext(e)
          }}
          slidePrev={(e) => {
            carousel.current?.setState((state) => ({ ...state, isAutoPlaying: false }))
            carousel.current?.slidePrev(e)
          }}
        />
      </div>
    </Container>
  )
}
