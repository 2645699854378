import styled from '@emotion/styled'
import { AvailableStatus } from 'components/availableStatus/AvailableStatus'
import { Button } from 'components/button/Button'
import { HStack, Stack } from 'components/Stack'
import { useTranslation } from 'next-i18next'
import Image from 'next/legacy/image'
import React from 'react'
import { SUPPORT_URL } from 'utils/constants'

export const SupportSectionContactUs = () => {
  const { t } = useTranslation()

  const imgNames = ['trust_support_lady', 'support_gentleman_1', 'support_gentleman_2'] as const

  return (
    <Stack css={{ marginTop: '1.5rem', alignItems: 'flex-start' }} space="2rem">
      <HStack space="1rem">
        <SHStack space="0">
          {imgNames.map((imgName) => (
            <div key={imgName}>
              <Image
                src={`/images/${imgName}.png`}
                height={56}
                width={56}
                alt="support person"
                loading="lazy"
              />
            </div>
          ))}
        </SHStack>
        <AvailableStatus text={t('common.available_now')} />
      </HStack>
      <Button type="button" variant="text" href={SUPPORT_URL}>
        {t('common.contact_us')}
      </Button>
    </Stack>
  )
}

const SHStack = styled(HStack)`
  & > *:not(:first-child) {
    margin-left: -0.75rem;
  }
`
