import { css } from '@emotion/react'
import { Mention } from '../mention'

export function Logo(props: { mention: Mention }) {
  if (!props.mention.logo?.url) {
    return null
  }

  return (
    <img
      src={props.mention.logo?.url}
      alt={props.mention.name || 'Logo'}
      css={css`
        max-width: 70px;
        max-height: 44px;
      `}
    />
  )
}
