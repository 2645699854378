import styled from '@emotion/styled'
import { Block } from 'components/Block'
import { Caption, Title } from 'components/Typography'
import { colors } from 'utils/colors'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SBlock = styled(Block)<{
  tone?: 'standard' | 'accent' | 'light-blue' | 'trust'
}>(({ tone }) => ({
  backgroundColor:
    tone === 'accent'
      ? colors.orange[100]
      : tone === 'light-blue'
      ? colors.blue[50]
      : tone === 'trust'
      ? colors.gray[50]
      : undefined,
}))

export const SHero = styled.section`
  grid-column-gap: 4rem;

  display: grid;
  align-items: end;

  grid-template-columns: 4fr 5fr;

  @media ${BREAKPOINTS.LG.max} {
    grid-column: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
`

export const SImage = styled.div`
  position: relative;
  width: 100%;
  & img,
  picture {
    width: 100%;
  }
`

export const SLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${BREAKPOINTS.LG.min} {
    align-items: flex-start;
  }
`

export const STitle = styled(Title)`
  margin-bottom: 1.5rem;
  text-align: left;

  white-space: pre-line;

  @media ${BREAKPOINTS.MD.max} {
    text-align: center;
    margin-bottom: 1rem;
  }
  b {
    font-weight: 600;
  }
`

export const SDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  text-align: left;
  line-height: ${pxToRem(28)}rem;

  & button {
    width: min-content;
  }

  @media ${BREAKPOINTS.MD.max} {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }
`

export const SCaption = styled(Caption)`
  @media ${BREAKPOINTS.MD.max} {
    text-align: center;
    max-width: 80%;
  }
`
