import { css } from '@emotion/react'
import { Button } from 'components/button/Button'
import { MouseEvent } from 'react'
import { Icon } from 'components/icon'
import { Block } from 'components/Block'
import { IconWrapper } from 'components/iconWrapper/IconWrapper'
import { colors } from 'utils/colors'

export function Controls(props: {
  className?: string
  slideNext(e: MouseEvent): void
  slidePrev(e: MouseEvent): void
  iconsAreChevron?: boolean
}) {
  return (
    <Block className={props.className}>
      <div
        css={css`
          display: flex;
          gap: 1rem;
          justify-content: end;
        `}
      >
        <Button
          type="button"
          variant="text"
          onClick={(e) => {
            props.slidePrev(e)
          }}
        >
          {props.iconsAreChevron ? (
            <IconWrapper
              size={32}
              backgroundColor={colors.white}
              css={css`
                box-shadow: 0 5px 40px 0 #d2dce1;
              `}
            >
              <Icon name="ChevronLeft" color={colors.blue[500]} size="1.5rem" />
            </IconWrapper>
          ) : (
            <Icon name="ArrowLeft" />
          )}
        </Button>
        <Button
          type="button"
          variant="text"
          onClick={(e) => {
            props.slideNext(e)
          }}
        >
          {props.iconsAreChevron ? (
            <IconWrapper
              size={32}
              backgroundColor={colors.white}
              css={css`
                box-shadow: 0 5px 40px 0 #d2dce1;
              `}
            >
              <Icon name="ChevronRight" color={colors.blue[500]} size="1.5rem" />
            </IconWrapper>
          ) : (
            <Icon name="ArrowRight" />
          )}
        </Button>
      </div>
    </Block>
  )
}
