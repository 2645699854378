import { BasicTextImageSection } from 'components/content/basicTextImageSection/BasicTextImageSection'
import { SupportSectionContactUs } from 'components/supportSectionContactUs/SupportSectionContactUs'
import { useTranslation } from 'next-i18next'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'

export const SupportSection = () => {
  const { t } = useTranslation()
  const images = useContentfulImages()

  return (
    <BasicTextImageSection
      orientation="left"
      title={t('common.section_support.title')}
      desc={t('common.section_support.description')}
      image={images.section_support}
      belowDescElement={<SupportSectionContactUs />}
    />
  )
}
