import { InterpolationPrimitive } from '@emotion/serialize'
import { ContentfulVideoSlug } from 'api/contentful/slugs/video'
import { Block } from 'components/Block'
import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { Spacer } from 'components/spacer/Spacer'
import { Title_h3_default } from 'components/Typography'
import { VideoButton } from 'components/videoButton/VideoButton'
import React, { ReactElement, ReactNode } from 'react'
import { useWindowSize } from 'utils/hooks'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
import {
  SBlock,
  SCaption,
  SDetails,
  SHero,
  SImage,
  SLeftColumn,
  STitle,
} from './LandingPageHero.styled'

interface LandingPageHeroProps {
  caption?: string
  title?: ReactNode
  image?: ReactElement
  className?: string
  children?: ReactNode
  mobileVariant?: 'legacy' | 'modern'
  tone?: 'standard' | 'accent' | 'light-blue' | 'trust'
  videoButton?: ContentfulVideoSlug
  maxTitleWidthDesktop?: string
  heroCSS?: InterpolationPrimitive
  overflowing?: boolean
  blockWidth?: string
}

export function LandingPageHero(props: LandingPageHeroProps) {
  const mobileVariant = props.mobileVariant ?? 'legacy'
  const { maxTitleWidthDesktop = pxToRemUnit(561) } = props
  const { isTablet } = useWindowSize()

  const imageFragment = (
    <>
      {props.image}
      {props.videoButton && <VideoButton videoButtonType={props.videoButton} />}
    </>
  )

  return (
    <SBlock
      className={props.className}
      blockWidth={props.blockWidth}
      tone={props.tone ?? 'standard'}
      css={
        mobileVariant === 'modern' &&
        BREAKPOINTS.LG.css({
          '&&': {
            paddingTop: 0,
          },
        })
      }
    >
      {mobileVariant === 'modern' && (
        <ConditionalWrapper
          condition={!!isTablet}
          wrapper={(children) => <Block>{children}</Block>}
        >
          <div
            css={[
              { gridColumn: 'left / right', display: 'none' },
              BREAKPOINTS.LG.css({
                display: 'block',
              }),
            ]}
          >
            <SImage>{imageFragment}</SImage>
            <Spacer size={1} />
          </div>
        </ConditionalWrapper>
      )}
      <SHero css={[{ gridColumn: props.overflowing ? 'center / right' : 'center' }, props.heroCSS]}>
        <SLeftColumn>
          {!!props.caption && <SCaption>{props.caption}</SCaption>}
          <Spacer size={1} />
          <STitle
            variant={Title_h3_default}
            as={'h1'}
            css={{
              [`@media ${BREAKPOINTS.MD.min}`]: {
                maxWidth: maxTitleWidthDesktop,
              },
            }}
          >
            {props.title}
          </STitle>
          <SDetails>{props.children}</SDetails>
        </SLeftColumn>
        <SImage css={mobileVariant === 'modern' && BREAKPOINTS.LG.css({ display: 'none' })}>
          {imageFragment}
        </SImage>
      </SHero>
    </SBlock>
  )
}
