import { css } from '@emotion/react'
import { HStack, Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { Mention } from '../mention'

function PersonPhoto(props: { mention: Mention }) {
  if (!props.mention.personPhoto?.url) {
    return null
  }
  return (
    <img
      src={props.mention.personPhoto.url}
      alt={'Reviewer personal photo'}
      width={44}
      height={44}
      css={css`
        border-radius: 100%;
      `}
    />
  )
}

export function Person(props: { mention: Mention }) {
  return (
    <HStack space={'0.75rem'}>
      <PersonPhoto mention={props.mention} />
      <Stack>
        <Text variant={'bodyMediumBold'} color="black">
          {props.mention.personName}
        </Text>
      </Stack>
    </HStack>
  )
}
