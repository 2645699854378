import styled from '@emotion/styled'
import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { LandingPageTextImageMediaMentions } from 'components/landingPageTextImageSection/LandingPageTextImageMediaMentions'
import { Stack } from 'components/Stack'
import { Caption, Title, Title_h4_default } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { MediaMentionsSwiper } from 'components/content/mediaMentions/MediaMentionsSwiper'
import { ContentfulMentions } from 'api/contentful/getContentfulMentions'
import { isNonNullable } from 'utils/general'
import { MentionItem } from 'api/contentful/contentful.graphql.types'

export const TestimonialsSection = ({
  mediaMentions,
  responsiveItemCounts,
  mentions,
}: {
  mediaMentions: ContentfulMediaMentions
  responsiveItemCounts: [number, number, number]
  mentions: ContentfulMentions
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'common.trust_pilot_reviews_section',
  })

  const mentionItems = mentions?.reviewsListCollection?.items.filter(isNonNullable) as MentionItem[]
  return (
    <SSection>
      <SWrapper>
        <Stack space="1.5rem" css={{ alignItems: 'center', textAlign: 'center' }}>
          <Caption>
            <Trans t={t} i18nKey="caption" />
          </Caption>

          <Title variant={Title_h4_default}>
            <Trans t={t} i18nKey="title" />
          </Title>
          <LandingPageTextImageMediaMentions
            mentions={mediaMentions}
            responsiveItemCounts={responsiveItemCounts}
          />

          {mentionItems && <MediaMentionsSwiper mentions={mentionItems} />}
        </Stack>
      </SWrapper>
    </SSection>
  )
}

const SSection = styled.section`
  background-color: var(--c-gray300);
`

const SWrapper = styled.div`
  padding: 2.625rem 0;
`
