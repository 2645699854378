import ReviewStarsHalf from 'images/extra-svg/ReviewStarsHalf.svg'
import ReviewStarsFull from 'images/extra-svg/ReviewStarsFull.svg'

export function Stars(props: { count: number | null | undefined }) {
  if (props.count == null) {
    return null
  }

  if (props.count < 5) {
    return <ReviewStarsHalf />
  } else {
    return <ReviewStarsFull />
  }
}
