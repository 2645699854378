import styled from '@emotion/styled'
import { HStack } from 'components/Stack'
import { Text } from 'components/Typography'

export const SAvailableWrapper = styled(HStack)`
  background: #d7efed;
  padding: 0.625rem 1rem;
  border-radius: 2.5rem;
`

export const SDot = styled.span`
  width: 8px;
  height: 8px;
  background: #00bca5;
  border-radius: 50%;
  display: block;
`

export const SText = styled(Text)`
  color: #00a591;
`
