import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { GoodTrustReferences } from 'components/estatePlanning/landing/references/GoodTrustReferences'
import { ContentfulImage } from 'components/Image'
import { HStack, Stack } from 'components/Stack'
import { Title, Title_h4_default } from 'components/Typography'
import { startDirectiveFlow } from 'logic/directive/create/start'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { Trans, useTranslation } from 'next-i18next'
import { FC } from 'react'
import { EventArgs } from 'utils/analytics/types'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { colors } from 'utils/colors'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { toLowerCase } from 'utils/general'

export interface IEstatePlanningSignupSectionProps {
  mediaMentions: ContentfulMediaMentions
  directiveType?: SimpleFlowDirectiveType
  place: EventArgs['est_flow_started']['place']
}

export const EstatePlanningSignupSection: FC<IEstatePlanningSignupSectionProps> = ({
  mediaMentions,
  directiveType,
  place,
}) => {
  const { isLogged } = useAuth()
  const images = useContentfulImages()
  const { t: unprefixedT } = useTranslation('estate_planning_lp')
  const { t } = useTranslation('estate_planning_lp', {
    keyPrefix: 'estate_planning_lp.section_signup',
  })

  const showWill = directiveType == null || directiveType === 'WILL'
  const showTrust = directiveType == null || directiveType === 'TRUST'

  return (
    <Block css={{ textAlign: 'center', padding: '2rem 0', backgroundColor: colors.gray[300] }}>
      <Stack gap="1.5rem" css={{ maxWidth: '50rem', justifySelf: 'center', alignItems: 'center' }}>
        <div css={{ width: '100%', maxWidth: '28rem' }}>
          <ContentfulImage {...images.estate_planning_section_signup} />
        </div>
        <Title variant={Title_h4_default}>
          <Trans
            t={t}
            i18nKey="title"
            context={directiveType ? toLowerCase(directiveType) : undefined}
          />
        </Title>

        <HStack gap="1.5rem">
          {showWill && (
            <Button
              type="button"
              size="large"
              onClick={() =>
                startDirectiveFlow('WILL', {
                  routerMechanism: 'push',
                  simpleFlowPlace: place,
                  isLogged: !!isLogged,
                  fromInside: false,
                })
              }
            >
              <Trans t={t} i18nKey="start_will" />
            </Button>
          )}
          {showTrust && (
            <Button
              type="button"
              size="large"
              onClick={() =>
                startDirectiveFlow('TRUST', {
                  routerMechanism: 'push',
                  simpleFlowPlace: place,
                  isLogged: !!isLogged,
                  fromInside: false,
                })
              }
              tone="trust"
            >
              <Trans t={t} i18nKey="start_trust" />
            </Button>
          )}
        </HStack>
        <GoodTrustReferences
          mediaMentions={mediaMentions}
          caption={<Trans t={unprefixedT} i18nKey="estate_planning_lp.references.caption" />}
          responsiveItemCounts={[5, 5, 5]}
        />
      </Stack>
    </Block>
  )
}
