import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { LandingPageTextImageMediaMentions } from 'components/landingPageTextImageSection/LandingPageTextImageMediaMentions'
import { Stack } from 'components/Stack'
import { Caption } from 'components/Typography'
import { FC, ReactNode } from 'react'
import { BREAKPOINTS } from 'utils/styled'

export const GoodTrustReferences: FC<{
  mediaMentions: ContentfulMediaMentions
  caption: ReactNode
  responsiveItemCounts?: [number, number, number]
}> = ({ mediaMentions, caption, responsiveItemCounts }) => {
  return (
    <Stack
      gap={['1.5rem', '2rem']}
      orientation={['vertical', 'horizontal']}
      css={BREAKPOINTS.MD.css({
        alignItems: 'center',
      })}
    >
      <MediaReferences
        mediaMentions={mediaMentions}
        caption={caption}
        responsiveItemCounts={responsiveItemCounts}
      />
    </Stack>
  )
}

const MediaReferences: FC<{
  mediaMentions: ContentfulMediaMentions
  caption: ReactNode
  responsiveItemCounts?: [number, number, number]
}> = ({ mediaMentions, caption, responsiveItemCounts }) => {
  return (
    <Stack
      gap={['1.5rem', '0']}
      css={[{ textAlign: 'left' }, BREAKPOINTS.MD.css({ textAlign: 'center' })]}
    >
      <Caption>{caption}</Caption>
      <LandingPageTextImageMediaMentions
        mentions={mediaMentions}
        responsiveItemCounts={responsiveItemCounts}
      />
    </Stack>
  )
}
